<template>
  <v-dialog
    class="AssigneeDialog"
    :value="issues.length > 0"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :max-width="480"
    :persistent="actionInProgress"
    @input="!$event && close()"
  >
    <v-sheet
      color="white"
      class="AssigneeDialog__card"
      :min-height="460"
      :max-height="636"
      @keydown.stop
      @keyup.stop
      @keypress.stop
    >
      <h1
        class="AssigneeDialog__title display-3 pt-12 px-12"
        :class="{
          'mb-6': !canEdit,
          'mb-4': canEdit,
        }"
        v-text="$t('issue.AssignIssues')"
      />

      <v-btn
        icon
        absolute
        top
        right
        @click="close"
      >
        <v-icon v-text="'mdi-close'" />
      </v-btn>

      <AppTextField
        v-show="canEdit"
        ref="quickSearch"
        v-model="quickSearch"
        filled
        :label="$t('issue.EmailOrUsername')"
        hide-details
        :autofocus="invite"
        style="margin: 0 48px 20px"
        @focus="invite = true"
      >
        <template
          v-if="invite"
          #append
        >
          <v-btn
            icon
            @click.stop="closeInvite"
          >
            <v-icon v-text="'mdi-close-circle'" />
          </v-btn>
        </template>
      </AppTextField>

      <div
        v-show="!invite"
        ref="scrollable"
        class="AssigneeDialog__scrollable"
      >
        <AssigneeDialogPermissions
          v-if="usersWithPermissions"
          :users-with-permissions="usersWithPermissions"
          :readonly="!canEdit"
          :loading="actionInProgress"
          @set-permission="setPermission"
          @remove-assignee="removeAssignee($event.user.id)"
        />
      </div>

      <div
        v-show="invite"
        ref="scrollable"
        class="AssigneeDialog__scrollable"
      >
        <TeamMember
          v-for="user in filteredUsersToInvite"
          :key="user.id"
          :user="user"
          :disabled="actionInProgress"
          @click="addAssignee(user.id)"
        >
          <template #perm>
            <TeamMemberAssigneeRole
              :is-show-remove="false"
              :permission-level="fakeUsersPermission[user.id] || DEFAULT_ISSUE_PERMISSION"
              :disabled="false"
              @set-permission="setPermissionBeforeAdded($event, user)"
            />
          </template>

          <template #append>
            <v-list-item-action class="my-0 pr-4">
              <v-btn
                icon
                :disabled="actionInProgress"
                @mousedown.stop
                @touchstart.stop
                @click.stop="addAssignee(user.id, fakeUsersPermission[user.id] || DEFAULT_ISSUE_PERMISSION)"
              >
                <v-icon v-text="'mdi-plus'" />
              </v-btn>
            </v-list-item-action>
          </template>
        </TeamMember>

        <v-list-item v-if="usersToInvite != null && usersToInvite.length === 0">
          <v-list-item-content class="pl-6">
            <v-list-item-title>{{ $t('issue.NoUsersToAssignM') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('issue.AllAssignedM') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="filteredUsersToInvite != null && filteredUsersToInvite.length === 0">
          <v-list-item-content class="pl-6">
            <v-list-item-title>{{ $t('issue.NoUsersMatchM') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('issue.NoUsersMatchQueryM', { quickSearch }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import * as R from 'ramda'

import {
  PROJECT_PERMISSION_LEVEL as PROJECT_PERM,
  ISSUE_PERMISSION_LEVEL as ISSUE_PERM,
  DEFAULT_ISSUE_PERMISSION, ISSUE_PERMISSION_LEVEL as PERM,
} from '../constants'

import AssigneeDialogPermissions from './AssigneeDialogPermissions'
import TeamMember from './TeamMember'
import TeamMemberAssigneeRole from '@/components/TeamMemberAssigneeRole'

const issuesEqual = (issues, otherIssues) =>
  R.equals(
    issues.map(issue => issue.id).sort(),
    otherIssues.map(issue => issue.id).sort())

// Tries to determine single permission based on the existing `issuePermissions`
// of a user and list of the selected `issues`. Unless permissions are uniform
// it returns mixed permission level.
// * If user has 'EDIT' access to all issues: returns 'EDIT'
// * If user has 'READ' access to all issues: returns 'READ'
// * Otherwise (user has access only to *some* of the `issues`
//   or permissions are both `EDIT` and `READ` for different issues):
//     returns '_MIXED'
const getComplexPermissionLevel = (issuePermissions, issues) => {
  if (!R.equals(
    issues.map(issue => issue.id).sort(),
    issuePermissions.map(({ issueId }) => issueId).sort(),
  )) return ISSUE_PERM._MIXED.value

  let finalPermissionLevel = null
  for (const perm of issuePermissions) {
    if (finalPermissionLevel == null) {
      finalPermissionLevel = ISSUE_PERM[perm.level]?.value ?? null
    } else if (finalPermissionLevel !== perm.level) {
      finalPermissionLevel = ISSUE_PERM._MIXED.value
    }
  }
  return finalPermissionLevel
}

export default {
  name: 'AssigneeDialog',

  components: {
    AssigneeDialogPermissions,
    TeamMember,
    TeamMemberAssigneeRole,
  },

  props: {
    projectId: { type: String, required: true },
    issues: { type: Array, default: () => [] },
  },

  data() {
    return {
      PERM,
      DEFAULT_ISSUE_PERMISSION,

      innerIssues: [...this.issues],
      quickSearch: '',
      invite: false,
      actionInProgress: false,
      fakeUsersPermission: {}, // Object<key: userUuid, value: permission>
    }
  },

  computed: {
    otherPermissions() {
      return [
        PERM.EDIT,
        PERM.READ,
      ]
    },

    currentUser() {
      const { $store } = this
      return $store.getters['user/current']
    },

    // list of all permissions for the project
    // Array<ProjectPermission>
    projectPermissions() {
      const { $store, projectId } = this
      return $store.getters['permission/forProject'](projectId)
    },

    // Lookup for assignees for every issue
    // Object<IssueId, Array<IssuePermission>>
    issuePermissions() {
      const { $store, innerIssues: issues } = this
      return issues && issues.reduce((map, issue) => {
        map[issue.id] = $store.getters['permission/forIssue'](issue.id)
        return map
      }, {})
    },

    // Reverse lookup for assignee permissions by user id
    // Object<UserId, Array<IssuePermission>>
    reverseIssuePermissions() {
      const { issuePermissions } = this
      if (
        !issuePermissions ||
        Object.values(issuePermissions).every(R.isNil)
      ) return null

      return Object.values(issuePermissions).reduce((res, permissions) => {
        for (const perm of (permissions || [])) {
          if (!R.has(perm.userId, res)) res[perm.userId] = []
          res[perm.userId].push(perm)
        }
        return res
      }, {})
    },

    // final rows displayed as assignees
    usersWithPermissions() {
      const { $store, reverseIssuePermissions, innerIssues: issues } = this
      if (!reverseIssuePermissions) return null

      const rows = Object.entries(reverseIssuePermissions)
        .map(([userId, issuePermissions]) => ({
          id: userId, // key for iteration
          user: $store.getters['user/get'](userId),
          // gets assignee level: 'READ', 'EDIT', or mixed
          level: getComplexPermissionLevel(issuePermissions, issues),
        }))
      return R.sortWith([
        R.ascend(({ level }) => ISSUE_PERM[level]?.sort ?? Infinity),
        R.ascend(({ user }) => user.userLogin),
      ])(rows)
    },

    // All active users (members of the project and not)
    // Array<User>
    allActiveUsers() {
      const { $store } = this
      const users = $store.getters['user/active']
      return users && users.map(user => ({
        ...user,
        _searchBy: [
          user.firstName,
          user.lastName,
          user.userLogin,
          user.userEmail,
        ]
          .filter(Boolean)
          .map(s => s.toLocaleLowerCase())
          .join(' '),
      }))
    },

    // all users except: (1) project members (2) assignees
    // [UPDATE] PTS-71: all users except assignees
    // Array<User>
    usersToInvite() {
      const {
        allActiveUsers: users,
        projectPermissions: permissions,
        usersWithPermissions: assigneeRows,
      } = this
      if ([users, permissions, assigneeRows].some(v => v == null)) return null

      // Disabled: PTS-71
      // const memberIds = permissions
      //   .filter(perm =>
      //     [PROJECT_PERM.OWNER, PROJECT_PERM.EDITOR, PROJECT_PERM.READONLY]
      //       .map(perm => perm.value)
      //       .includes(perm.level),
      //   )
      //   .map(perm => perm.userId)
      const assigneeIds = assigneeRows.map(row => row.user.id)
      const excludeUserIds = [
        // ...memberIds, // Disabled: PTS-71
        ...assigneeIds,
      ]

      return users.filter(user => !excludeUserIds.includes(user.id))
    },

    // quick search tokens to filter `usersToInvite`
    // Array<String>
    searchTokens() {
      const { quickSearch } = this
      return quickSearch
        .split(/\s+?/g)
        .map(s => s.toLocaleLowerCase())
        .filter(Boolean)
    },

    // Filtered users to invite
    // Array<User>
    filteredUsersToInvite() {
      const { usersToInvite: users, searchTokens } = this
      if (!searchTokens.length) return users
      return users && users.filter(({ _searchBy }) =>
        searchTokens.every(q => _searchBy.includes(q)))
    },

    // Is the current user privileged enough to edit assignees
    // Boolean | null
    canEdit() {
      const { currentUser, projectPermissions: permissions } = this

      if (!currentUser) return null
      if (currentUser.isAdmin) return true

      if (!permissions) return null

      const editorPerms = [PROJECT_PERM.EDITOR.value, PROJECT_PERM.OWNER.value]
      return permissions.some(perm =>
        perm.userId === currentUser.id &&
        editorPerms.includes(perm.level))
    },
  },

  watch: {
    // stores issues inside inner `data`
    issues(issues) {
      if (issuesEqual(issues, this.innerIssues)) return
      if (issues.length) this.innerIssues = [...issues]
      else {
        setTimeout(() => {
          this.innerIssues = [...this.issues]
        }, 300)
      }
    },

    // loads permissions for selected issues
    innerIssues: {
      async handler(issues) {
        this.reset()
        if (issues.length) await this.fetchIssuesPermissions()
        this.maybeSwitchToInvite()
      },
      immediate: true,
    },

    // loads permission for the current project (owners, editors, etc.)
    projectId: {
      async handler(projectId) {
        if (projectId) await this.fetchProjectPermissions()
        this.maybeSwitchToInvite()
      },
      immediate: true,
    },
  },

  methods: {
    close() { this.$emit('update:issues', []) },

    reset() {
      this.quickSearch = ''
      this.invite = false
      this.fakeUsersPermission = {}
      this.$nextTick(() => this.$refs.scrollable?.scroll?.(0, 0))
    },

    fetchIssuesPermissions() {
      const { $store, projectId, innerIssues: issues } = this
      if (!issues) return Promise.resolve([])
      return Promise.all(
        (issues || [])
          .map(({ id: issueId }) =>
            $store.dispatch('permission/getIssuePermissions', {
              projectId,
              issueId,
            }),
          ),
      )
    },

    fetchProjectPermissions() {
      const { $store, projectId, innerIssues: issues } = this
      if (!issues) return Promise.resolve([])
      return $store.dispatch('permission/getProjectPermissions', {
        projectId,
      })
    },

    action(promise) {
      this.actionInProgress = true
      return promise.finally(() => { this.actionInProgress = false })
    },

    async closeInvite() {
      this.invite = false
      this.quickSearch = ''
      await this.$nextTick()
      this.$refs.quickSearch.$el.querySelector('input').blur()
    },

    // Opens the confirm dialog before permission is about to be applied
    // Resolves with `true` on consent, `false` otherwise
    // async<String -> Boolean>
    async confirmPermissionChange(permissionLevel) {
      const { $store } = this

      if (permissionLevel === ISSUE_PERM.EDIT.value) {
        return await $store.dispatch('confirm/openDialog', {
          title: this.$t('issue.MakeEditorQ'),
          subtitle: this.$t('issue.EditorM'),
          consentLabel: this.$t('issue.MakeEditor'),
        })
      }

      if (permissionLevel === ISSUE_PERM.READ.value) {
        return await $store.dispatch('confirm/openDialog', {
          title: this.$t('issue.MakeReadOnlyQ'),
          subtitle: this.$t('issue.ReadOnlyM'),
          consentLabel: this.$t('issue.MakeReadOnly'),
        })
      }

      return true
    },

    setPermissionBeforeAdded(e, user) {
      this.$set(this.fakeUsersPermission, user.id, e.value)
    },

    // Prompts user agreement (via modal dialog) and then
    // applies provided permission `level` to all of the selected issues
    // async<{ user: User, level: String } -> Array<any>>
    async setPermission({ user: { id: userId }, level: permissionLevel }) {
      const { $store, projectId, innerIssues: issues } = this

      // prompt user agreement
      const agreed = await this.confirmPermissionChange(permissionLevel)
      if (!agreed) return

      // get `user` assignments and group them by `issueId`
      const { reverseIssuePermissions: user2perms } = this
      const userPermissions = user2perms[userId] ?? []
      const issue2perm = userPermissions.reduce((acc, perm) => {
        acc[perm.issueId] = perm
        return acc
      }, {})

      // fire create/update action
      const done = $store.dispatch('permission/changeIssuePermissions', {
        projectId,
        issueIds: issues
          .map(R.prop('id'))
          .filter(issueId => {
            const existingPerm = issue2perm[issueId]
            return existingPerm?.level !== permissionLevel
          }),
        userId,
        level: permissionLevel,
      })

      // apply saving/loading/disabled state
      await this.action((async () => {
        await done
        await Promise.all([
          this.fetchProjectPermissions(),
          this.fetchIssuesPermissions(),
        ])
      })())
    },

    // removes user from assignees
    // async<UserId -> Array<any>>
    async removeAssignee(userId) {
      const { $store, projectId, innerIssues: issues } = this

      // get `user` assignments and group them by `issueId`
      const { reverseIssuePermissions: user2perms } = this
      const userPermissions = user2perms[userId] ?? []
      const issue2perm = userPermissions.reduce((acc, perm) => {
        acc[perm.issueId] = perm
        return acc
      }, {})

      // promise to delete existing permissions
      const deleted = $store.dispatch('permission/deleteIssuePermissions', {
        projectId,
        issueIds: issues
          .map(R.prop('id'))
          .filter(issueId => issue2perm[issueId] != null),
        userId,
      })

      // apply saving/loading/disabled state
      await this.action((async () => {
        await deleted
        await Promise.all([
          this.fetchProjectPermissions(),
          this.fetchIssuesPermissions(),
        ])
      })())
    },

    // Adds a new assignee with the given `permissionLevel`
    // async<UserId -> String -> Array<any>>
    async addAssignee(userId, permissionLevel = DEFAULT_ISSUE_PERMISSION) {
      const { $store, projectId, innerIssues: issues } = this
      const created = $store.dispatch('permission/changeIssuePermissions', {
        projectId,
        issueIds: issues.map(R.prop('id')),
        userId,
        level: permissionLevel,
      })
      await this.action((async () => {
        await created
        await Promise.all([
          this.fetchProjectPermissions(),
          this.fetchIssuesPermissions(),
        ])
      })())
    },

    // Called after permissions are loaded, switches to invite view
    // if required (namely there are no assignees)
    // () => void
    async maybeSwitchToInvite() {
      const { usersWithPermissions } = this

      // not loaded yet
      if (usersWithPermissions == null) return
      // there are some assignees
      if (usersWithPermissions.length > 0) return
      // no assignees - switch to invite
      this.invite = true

      // focus input - autofocus sometimes fails :'(
      await this.$nextTick()
      this.$refs.quickSearch?.focus?.() // eslint-disable-line
    },
  },
}
</script>

<style lang="sass" scoped>
.AssigneeDialog
  &__card
    position: relative
    padding-bottom: 32px

  &__scrollable
    max-height: 416px
    overflow: hidden auto
</style>

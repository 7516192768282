<template>
  <AppBarLayout
    class="IssueListAppBar"
    :action-buttons-class="{
      'ml-16': $vuetify.breakpoint.lgAndUp,
      'ml-3': $vuetify.breakpoint.mdAndDown,
    }"
  >
    <template
      v-if="!projectId && !cardId"
      #header
    >
      {{ $t('issue.AllIssues') }}
    </template>
    <template
      v-else
      #header
    >
      {{ $t('issue.Issues') }}
    </template>

    <router-link
      v-if="cardId"
      v-show="card !== null"
      v-ripple="{ class: 'app-ripple' }"
      class="IssueListAppBar__title page-title app-bar__link mr-4"
      :to="backRoute"
      active-class="_"
    >
      <v-btn
        icon
        color="#8492A6"
      >
        <v-icon
          size="16"
          v-text="'$left'"
        />
      </v-btn>

      <!-- it doesn't work, so disabling for now -->
      <span
        v-if="false"
        class="page-title__card-badge"
        :style="{ ...cardStyles }"
        v-text="$t('issue.card')"
      />

      <span
        class="page-title__title ml-2"
        v-text="card ? card.name : '...'"
      />

      <span
        class="page-title__comment ml-2"
        :class="{ 'flex-shrink-0': !drillDownComment }"
        v-text="drillDownComment ? `(${drillDownComment})` : (issues && issues.length)"
      />
    </router-link>

    <template #actions>
      <div class="IssueListAppBar__actions actions d-flex flex-nowrap">
        <v-btn
          key="export"
          color="primary"
          outlined
          :disabled="!($store.state.entities.issue.$visibleOrder || []).length"
          @click="openCsvDialog"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-export-variant'"
          />
          {{ $t('issue.Csv') }}
        </v-btn>
        <v-btn
          v-if="!cardId"
          key="'save-new-card'"
          color="primary"
          depressed
          :disabled="projectId != null && !issueSchema"
          class="actions__save-new-card-btn ml-3"
          :to="createCardRoute"
        >
          <v-icon
            class="mr-2"
            v-text="'mdi-plus'"
          />
          {{ $t('issue.Card') }}
        </v-btn>
        <v-btn
          v-if="cardId"
          key="'update-existing-card'"
          color="primary"
          outlined
          class="actions__update-existing-card-btn ml-3"
          :disabled="!card"
          :to="updateCardRoute"
        >
          {{ $t('issue.Update') }}
        </v-btn>
        <v-btn
          v-if="cardId"
          key="'save-as-new-card'"
          color="primary"
          outlined
          class="actions__save-as-new-card-btn ml-2"
          :disabled="!card"
          :to="copyCardRoute"
        >
          {{ $t('issue.SaveAs') }}
        </v-btn>
      </div>
    </template>
  </AppBarLayout>
</template>

<script>
import * as R from 'ramda'

import {
  BG, CARD_TYPE, COLORS_LOOKUP,
  SCORE_LOOKUP,
} from '../constants'
import { reportError } from '../helpers'

import DashboardCard from '../store/orm/dashboardCard'
import Dialog from '../store/orm/dialog'
import Issue from '../store/orm/issue'

import AppBarLayout from '../layouts/AppBarLayout'

const projectIssueFieldValueDisplay = (issueStatusLookup, fieldProp, value) =>
  fieldProp === 'status'
    ? (issueStatusLookup[value] || { displayName: value }).displayName
    : (SCORE_LOOKUP[value] || { displayValue: value }).displayValue
const crossProjectIssueFieldValueDisplay = (vm, fieldProp, value) =>
  fieldProp === 'status'
    ? (vm.$store.getters['$issueStatus/get']({ statusName: value }) || { displayName: value }).displayName
    : (SCORE_LOOKUP[value] || { displayValue: value }).displayValue

export default {
  name: 'IssueListAppBar',

  components: {
    AppBarLayout,
  },

  props: {
    projectId: { type: String, default: null },

    // Drill-down props:
    // 1) Id of a dashboard card clicked
    cardId: { type: Number, default: null },

    // 2) [A/B counter only] subquery number was clicked;
    // relevant when there's a valid `cardId`,
    // when performing drill-down for `ab` card
    // `subQuery` is a path to the query, by default `card.query` is taken,
    // for `ab` card it can be 'subQuery'
    // to narrow filters by `card.query.subQuery`
    subQuery: { type: String, default: '' },

    // 3) [Table/pie only] which sector/cell was clicked
    // Pie sector examples: 'closed', 1
    // Table cell examples: '"closed"~1', 'null~2'
    groupingValue: { type: String, default: null },

    // list of selected issue objects (not just ids)
    selectedIssues: { type: Array, default: () => [] },
  },

  data: () => ({
    CARD_TYPE,
  }),

  computed: {
    selectedIssueIds() { return R.map(R.prop('id'), this.selectedIssues) },

    card() {
      const { cardId } = this
      return cardId && DashboardCard.find(cardId)
    },

    issues() {
      const { projectId } = this
      const q = Issue.query().withAllRecursive()
      return projectId
        ? q.where('projectID', projectId || null).all()
        : q.all()
    },

    filter() {
      const { $store, projectId } = this
      return $store.state.entities.issue.$filter[projectId || null]
    },

    issueStatusLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['$issueStatus/getLookup'](projectId)
    },

    backRoute() {
      const { projectId, cardId } = this
      return projectId
        ? {
          name: 'ProjectDashboard',
          params: { projectId },
          query: cardId ? { cardId } : {},
        }
        : {
          name: 'Dashboard',
          query: cardId ? { cardId } : {},
        }
    },

    createCardRoute() {
      const { $route, projectId, filter } = this
      return projectId
        ? {
          name: 'ProjectEditCard',
          params: { projectId },
          query: { ...filter, back: $route.fullPath },
        }
        : {
          name: 'EditCard',
          params: { projectId },
          query: { ...filter, back: $route.fullPath },
        }
    },

    updateCardRoute() {
      const { $route, projectId, cardId, subQuery, groupingValue, filter } = this
      return {
        name: projectId ? 'ProjectEditCard' : 'EditCard',
        params: projectId ? { projectId } : {},
        query: {
          ...(!subQuery && groupingValue == null ? filter : {}),
          cardId,
          back: $route.fullPath,
        },
      }
    },

    copyCardRoute() {
      const { updateCardRoute } = this
      return {
        ...updateCardRoute,
        query: {
          ...updateCardRoute.query,
          copy: 'true',
        },
      }
    },

    drillDownComment() {
      const {
        projectId,
        issueStatusLookup,
        card,
        subQuery,
        groupingValue,
      } = this

      if (!card || (projectId && !issueStatusLookup)) return null

      if (card.cardType === CARD_TYPE.aOfB && subQuery === 'subQuery') {
        return this.$t('issue.Subset')
      }

      if (card.cardType === CARD_TYPE.pieChart && groupingValue) {
        const field = card.query.groupBy
        const fieldNameDisplay = field // fixme: get from fieldsLookup
        // const fieldNameDisplay = ISSUE_FIELDS_SHORT_DISPLAY[field] || field
        const valueDisplay = projectId
          ? projectIssueFieldValueDisplay(issueStatusLookup, field, groupingValue)
          : crossProjectIssueFieldValueDisplay(this, field, groupingValue)
        return `${fieldNameDisplay} ${this.$t(valueDisplay)}`
      }

      if (card.cardType === CARD_TYPE.table && groupingValue) {
        try {
          const [xValue, yValue] = groupingValue.split('~')
            .map(JSON.parse.bind(JSON))

          const [xProp, yProp] = [card.query.xAxis, card.query.yAxis]

          // fixme: get from fieldsLookup
          const xFieldNameDisplay = xProp
          const yFieldNameDisplay = yProp
          // const xFieldNameDisplay = ISSUE_FIELDS_SHORT_DISPLAY[xProp] || xProp
          // const yFieldNameDisplay = ISSUE_FIELDS_SHORT_DISPLAY[yProp] || yProp

          // TODO: display values from the preset
          const [xValueDisplay, yValueDisplay] =
            [[xValue, xProp], [yValue, yProp]]
              .map(([value, field]) =>
                value &&
                (projectId
                  ? projectIssueFieldValueDisplay(issueStatusLookup, field, value)
                  : crossProjectIssueFieldValueDisplay(this, field, value)))

          const xDisplay =
            xValueDisplay && `${xFieldNameDisplay} ${this.$t(xValueDisplay)}`
          const yDisplay =
            yValueDisplay && `${yFieldNameDisplay} ${this.$t(yValueDisplay)}`

          return xDisplay && yDisplay
            ? `${yDisplay} / ${xDisplay}`
            : (xDisplay || yDisplay)
        } catch (e) {
          reportError(e)
        }
      }

      return null
    },

    // card styles: color, bg
    color() {
      const { card } = this
      const color = card && card.metaData && card.metaData.color
      const colorKey = color && R.has(color, COLORS_LOOKUP)
        ? color
        : null
      return colorKey && COLORS_LOOKUP[colorKey]
    },

    bg() {
      const { card } = this
      const pattern = card && card.metaData && card.metaData.pattern
      const patternKey = pattern && R.has(pattern, BG)
        ? pattern
        : null
      return patternKey && BG[patternKey]
    },

    cardStyles() {
      const { color, bg } = this

      if (!color || !bg) return {}
      return {
        backgroundColor: color.color,
        backgroundImage: `url(${bg.image})`,
      }
    },

    issueSchema() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/get'](projectId)
    },
  },

  methods: {
    openCsvDialog() {
      const { projectId, selectedIssueIds } = this
      Dialog.open({
        componentName: 'CsvExportIssuesDialog',
        props: { projectId, selectedIssueIds },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueListAppBar
  .page-title
    display: flex
    align-items: center
    color: inherit
    text-decoration: inherit
    overflow: hidden
    text-overflow: ellipsis
    // border-left: 1px solid rgba(0, 0, 0, .05)

    &__card-badge
      padding: 6px
      border-radius: 2px
      background-repeat: repeat
      background-size: 33%
      color: white

    &__title
      color: #3c3a52
      white-space: nowrap
      letter-spacing: normal
      overflow: hidden
      text-overflow: ellipsis
      font-size: 24px
      line-height: 32px
      font-weight: 500

    &__comment
      color: #8b90a0
      white-space: nowrap
      letter-spacing: normal
      overflow: hidden
      text-overflow: ellipsis
      font-size: 24px
      line-height: 32px
      font-weight: 500
</style>
